/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://3igvz544ezd5dplu5k24prtpsq.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ucekpearejfhvpf4sqtj6wptdm",
    "aws_cloud_logic_custom": [
        {
            "name": "v1",
            "endpoint": "https://qukndalfb8.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-3:3917287b-03cd-4d54-bd85-3ce937dd701e",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_xvup7EbIZ",
    "aws_user_pools_web_client_id": "oc0aurb22573i79uhet7cbni8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "oobjo-storage34530-dev",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
